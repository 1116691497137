import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { apiEndPoints } from "../../services/endpoints";
import { dataExists } from "../../helpers";
import { WebService, WebServiceMultipart } from "../../services/webServices";

const ImageUploadModal = ({
  visibility,
  setVisibility,
  mode,
  lang,
  user,
  setUser,
  setProfilePicture,
}) => {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setShow(visibility);
  }, [visibility]);

  const handleClose = () => setVisibility(false);

  const handleImageChange = (e) => {
    const _file = e.target.files[0];
    if (_file) {
      setFile(_file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the preview
      };
      reader.readAsDataURL(_file); // Read the file as Data URL
    }
  };

  const handleUpload = async () => {
    // Logic to upload the image
    console.log("Uploading Image...");

    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.updateProfilePicture}/`;

      let headers = {
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.token}`,
      };

      const formData = new FormData();
      formData.append("profile_picture", file);

      // let payload = {
      //   profile_picture: image,
      // };
      // try {
      let response = await WebService.post(fullUrl, formData, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        let _userJson = response?.data;
        console.log(_userJson);
        setVisibility(false);
        _userJson["token"] = user.token;
        _userJson["refresh"] = user.refresh;
        localStorage.setItem("user", JSON.stringify(_userJson));
        setUser(_userJson);
        setProfilePicture(_userJson.profile_picture);
      } else {
        // setError(localString[lang]["errorCreatingQuestion"]);
      }
    }

    handleClose();
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Upload Image
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Select Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Form.Group>
            {image && (
              <div className="text-center mt-3">
                <Image
                  src={image}
                  alt="Preview"
                  thumbnail
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageUploadModal;
