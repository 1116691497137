import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import {
  FORUM_CATEGORIES,
  FORUM_SUBCATEGORIES,
  LANGUAGES,
  scannerOptions,
} from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import useDebounce from "./Debounce";
import { Typeahead } from "react-bootstrap-typeahead";
import compaines from "../../data/companies.json";
import TopContributers from "./TopContributers";

const ForumCreate = ({ lang, mode, user, setForceShowLogin }) => {
  const [userFullname, setUserFullname] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [userTitle, setUserTitle] = useState(null);
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState(0);
  const [subcategory, setSubcategory] = useState(0);
  const [description, setDescription] = useState(null);
  const [shortDescription, setShortDescription] = useState(null);
  const [ticker, setTicker] = useState(null);
  const [loggedin, setLoggedIn] = useState(false);
  const [categoryData, setCategoryData] = useState(FORUM_CATEGORIES);
  const [subcategoryData, setSubcategoryData] = useState(FORUM_SUBCATEGORIES);
  const [error, setError] = useState(null);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  // const debouncedSlug = useDebounce(slug, 500);
  const searchref = useRef(null);

  const allowSubmit = () => {
    return !(
      loggedin &&
      title !== null &&
      title !== "" &&
      // validSlug &&
      description !== null &&
      description !== "" &&
      !submitting
    );
  };

  const createPost = async () => {
    let fullUrl = `${apiEndPoints.questions}/`;

    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    };

    let payload = {
      title: title,
      body: description,
      short_description: shortDescription,
      category: category,
      subcategory: subcategory,
      ticker: ticker,
    };
    if (category === 0 || category === "0") {
      payload["category"] = "Others";
    }
    if (subcategory === 0 || subcategory === "0") {
      payload["subcategory"] = "Ask me Anything";
    }
    // try {
    let response = await WebService.post(fullUrl, payload, {
      headers: headers,
    });
    if (response?.status >= 200 && response?.status < 300) {
      let data = response?.data;
      let slug = data["slug"];
      window.location = `/forum/${slug}`;
    } else {
      setError(localString[lang]["errorCreatingQuestion"]);
    }
  };

  useEffect(() => {
    if (dataExists(user)) {
      setLoggedIn(dataExists(user));
      setUserFullname(`${user.first_name} ${user.last_name}`);
      setUserTitle(user.title);
      setProfilePicture(user.profile_picture);
    } else {
      window.location = "/forum";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <div className="d-flex">
        <Stack
          className={`content screener forum-left ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
        >
          <div className="d-flex justify-content-between flex-row flex-wrap bd-highlight forum-container">
            <div className="d-flex justify-content-between align-items-center mt-2 mb-3 w-100">
              <div className="d-flex me-3">
                {profilePicture ? (
                  <img src={profilePicture} className="rounded-circle"></img>
                ) : (
                  <img src="/assets/user.png" className="rounded-circle"></img>
                )}
                <div>
                  <div className="screener-title">{userFullname}</div>
                  <div className="forum-list-title">
                    <div>{userTitle}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bd-highlight forum-container mt-0">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>{localString[lang]["title"]}</Form.Label>
                <Form.Control
                  type="text"
                  className={!title ? "border-danger-subtle" : ""}
                  placeholder="What is this about"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  {localString[lang]["shortDescriptionText"]}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  // className={!description ? "border-danger-subtle" : ""}
                  rows={2}
                  onChange={(e) => {
                    setShortDescription(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  {localString[lang]["forumCategories"]["Category"]}
                </Form.Label>
                <div className="d-flex">
                  <Form.Select
                    aria-label="Category select"
                    // size="sm"
                    className="w-50 me-2"
                    value={category}
                    onChange={(e) => {
                      let value = e.target.value;
                      // if (value === 0) {
                      //   setCategory("Others");
                      // } else {
                      setCategory(value);
                      setSubcategory(0);
                      // }
                    }}
                  >
                    {categoryData.map((_category) => {
                      return (
                        <option key={_category.name} value={_category.value}>
                          {localString[lang]["forumCategories"][_category.name]}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {category && category !== 0 ? (
                    <Form.Select
                      aria-label="Category select"
                      // size="sm"
                      className="w-50"
                      value={subcategory}
                      onChange={(e) => {
                        let value = e.target.value;
                        // if (value === 0) {
                        //   setCategory("Others");
                        // } else {
                        setSubcategory(value);
                        // }
                      }}
                    >
                      {subcategoryData[category]?.map((_subcategory) => {
                        return (
                          <option
                            key={_subcategory.name}
                            value={_subcategory.value}
                          >
                            {
                              localString[lang]["forumCategories"][
                                _subcategory.name
                              ]
                            }
                          </option>
                        );
                      })}
                    </Form.Select>
                  ) : (
                    <></>
                  )}
                </div>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  {localString[lang]["StockInDiscussion"]}
                </Form.Label>
                <Typeahead
                  className="typeahead-translate"
                  id={`typehead-search`}
                  ref={searchref}
                  input={`.typehead-search-ig`}
                  // value={ti}
                  onChange={(data) => {
                    if (data[0]) {
                      setTicker(data[0].ticker);
                    } else {
                      setTicker(null);
                    }
                  }}
                  inputProps={{
                    style: {
                      borderBottomLeftRadius: "3px",
                      borderBottomRightRadius: "3px",
                      borderTopLeftRadius: "3px",
                      borderTopRightRadius: "3px",
                      fontSize: "14px",
                    },
                  }}
                  options={compaines}
                  placeholder={localString[lang].search}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>{localString[lang]["post"]}</Form.Label>
                <Form.Control
                  as="textarea"
                  className={!description ? "border-danger-subtle" : ""}
                  rows={15}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {error !== null && (
                  <Form.Text className="error-essage" muted>
                    {error}
                  </Form.Text>
                )}
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <InputGroup className="pt-2">
                  <Button
                    disabled={allowSubmit()}
                    variant="primary"
                    id="button-addon2"
                    size="sm"
                    onClick={() => {
                      setSubmitting(true);
                      createPost();
                    }}
                  >
                    {localString[lang]["createPost"]}
                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>

          <div className="bd-highlight forum-container mt-0">
            <div className="screener-title mb-2">Community Guidelines</div>
            <div
              className={`forum-list-container ${
                mode === "dark" ? "forum-dark" : "forum-light"
              }`}
            >
              <ul>
                <li>
                  <strong>Stay Respectful and Constructive:</strong> Share
                  insights and opinions politely without personal attacks or
                  offensive language.
                </li>
                <li>
                  <strong>Focus on Stock Market Topics:</strong> Posts should be
                  relevant to investing, trading, or financial education. Avoid
                  unrelated content.
                </li>
                <li>
                  <strong>Avoid Misleading Information:</strong> Ensure all
                  shared data, charts, and analyses are accurate and
                  well-sourced. Speculative claims should be clearly stated as
                  opinions.
                </li>
                <li>
                  <strong>No Solicitation or Self-Promotion:</strong> Refrain
                  from advertising or promoting unrelated services or products.
                </li>
                <li>
                  <strong>Liability Disclaimer:</strong> Stock Penguins is not
                  liable for any communications, disputes, or decisions made
                  based on forum content.
                </li>
              </ul>
            </div>
          </div>
        </Stack>

        <Stack
          className={`content screener forum-right ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <TopContributers
            mode={mode}
            lang={lang}
            user={user}
            setForceShowLogin={setForceShowLogin}
          />
        </Stack>
      </div>
    </>
  );
};

export default ForumCreate;
