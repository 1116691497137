/* eslint-disable max-len */

// CONFIG constants
export const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "中文", // Chinese 1
    value: "ch",
  },
  {
    label: "Español", // Spanish 2
    value: "sp",
  },
  {
    label: "Français", // French 3
    value: "fr",
  },
  {
    label: "Deutsch", // German 4
    value: "gr",
  },
  {
    label: "Nederlands", // Dutch 5
    value: "du",
  },
  {
    label: "日本語", // Japanese 6
    value: "jp",
  },
  {
    label: "Italiana", // Italian 7
    value: "it",
  },
  {
    label: "한국어", // Korean 8
    value: "kr",
  },
  {
    label: "Tiếng Việt", // Vietnamese 9
    value: "vt",
  },
  {
    label: "Bahasa Indonesia", // Bahasa Indonesia 10
    value: "bi",
  },
];

export const THEMES = {
  DARK: "dark",
  LIGHT: "light",
};

export const DURATION = {
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
};

export const COLORS = {
  GRAPH: {
    PRICE: {
      borderColor: "#d9730e",
      backgroundColor: "#f28e2a",
    },
    MARKET_CAP: {
      borderColor: "#489894",
      backgroundColor: "#489894",
    },
    REVENUE: {
      borderColor: "#a0cbe8",
      backgroundColor: "#a0cbe8",
    },
    REVENUE_VS_NET_INCOME: {
      borderColor: "#549d3a",
      backgroundColor: "#549d3a",
    },
    NET_INCOME: {
      borderColor: "#e2483d",
      backgroundColor: "#e2483d",
    },
    EBITDA: {
      borderColor: "#009bf5",
      backgroundColor: "#009bf5",
    },
    EPS: {
      borderColor: "#ffb902",
      backgroundColor: "#ffb902",
    },
    TOTAL_EXPENSES: {
      borderColor: "#9c27b0",
      backgroundColor: "#9c27b0",
    },
    SHARES_ISSUED: {
      borderColor: "#fd7f6f",
      backgroundColor: "#fd7f6f",
    },
    FREE_CASH_FLOW: {
      borderColor: "#0bb4ff",
      backgroundColor: "#0bb4ff",
    },
    OPERATING_CASH_FLOW: {
      borderColor: "#f46a9b",
      backgroundColor: "#f46a9b",
    },
    DIVIDEND: {
      borderColor: "#549d3a",
      backgroundColor: "#87bc45",
    },
    GROSS_MARGIN: {
      borderColor: "#00b7c7",
      backgroundColor: "#00b7c7",
    },
    TOTAL_DEBT: {
      borderColor: "#ef9b20",
      backgroundColor: "#ef9b20",
    },
    TOTAL_CASH: {
      borderColor: "#00b7c7",
      backgroundColor: "#00b7c7",
    },
  },
};

export const LANG = LANGUAGES[0].value;

export const BILLION = 1000000000;
export const MILLION = 1000000;
export const NULL_TICKER = -99999;

export const scannerOptions = {
  exchange: [
    { name: "any", value: "any" },
    { name: "nasdaq", value: "ex_nasdaq" },
    { name: "nyse", value: "ex_nyse" },
    { name: "amex", value: "ex_amex" },
  ],
  marketCap: [
    { name: "any", value: "any" },
    { value: "mcap_mega", name: "mcap_mega" },
    { value: "mcap_large", name: "mcap_large" },
    { value: "mcap_mid", name: "mcap_mid" },
    { value: "mcap_small", name: "mcap_small" },
    { value: "mcap_micro", name: "mcap_micro" },
    { value: "mcap_nano", name: "mcap_nano" },
    { value: "mcap_largeover", name: "mcap_largeover" },
    { value: "mcap_midover", name: "mcap_midover" },
    { value: "mcap_smallover", name: "mcap_smallover" },
    { value: "mcap_microover", name: "mcap_microover" },
    { value: "mcap_largeunder", name: "mcap_largeunder" },
    { value: "mcap_midunder", name: "mcap_midunder" },
    { value: "mcap_smallunder", name: "mcap_smallunder" },
    { value: "mcap_microunder", name: "mcap_microunder" },
  ],
  performance: [
    { name: "any", value: "any" },
    { value: "perf_dup", name: "perf_dup" },
    { value: "perf_ddown", name: "perf_ddown" },
    { value: "perf_d15u", name: "perf_d15u" },
    { value: "perf_d10u", name: "perf_d10u" },
    { value: "perf_d10o", name: "perf_d10o" },
    { value: "perf_d15o", name: "perf_d15o" },
    { value: "perf_1w30u", name: "perf_1w30u" },
    { value: "perf_1w10u", name: "perf_1w10u" },
    { value: "perf_1wdown", name: "perf_1wdown" },
    { value: "perf_1wup", name: "perf_1wup" },
    { value: "perf_1w10o", name: "perf_1w10o" },
    { value: "perf_1w30o", name: "perf_1w30o" },
    { value: "perf_4w50u", name: "perf_4w50u" },
    { value: "perf_4w30u", name: "perf_4w30u" },
    { value: "perf_4w10u", name: "perf_4w10u" },
    { value: "perf_4wdown", name: "perf_4wdown" },
    { value: "perf_4wup", name: "perf_4wup" },
    { value: "perf_4w20o", name: "perf_4w20o" },
    { value: "perf_4w30o", name: "perf_4w30o" },
    { value: "perf_4w50o", name: "perf_4w50o" },
    { value: "perf_13w50u", name: "perf_13w50u" },
    { value: "perf_13w30u", name: "perf_13w30u" },
    { value: "perf_13w10u", name: "perf_13w10u" },
    { value: "perf_13wdown", name: "perf_13wdown" },
    { value: "perf_13wup", name: "perf_13wup" },
    { value: "perf_13w10o", name: "perf_13w10o" },
    { value: "perf_13w30o", name: "perf_13w30o" },
    { value: "perf_13w50o", name: "perf_13w50o" },
    { value: "perf_26w75u", name: "perf_26w75u" },
    { value: "perf_26w50u", name: "perf_26w50u" },
    { value: "perf_26w20u", name: "perf_26w20u" },
    { value: "perf_26w10u", name: "perf_26w10u" },
    { value: "perf_26wdown", name: "perf_26wdown" },
    { value: "perf_26wup", name: "perf_26wup" },
    { value: "perf_26w10o", name: "perf_26w10o" },
    { value: "perf_26w20o", name: "perf_26w20o" },
    { value: "perf_26w50o", name: "perf_26w50o" },
    { value: "perf_26w100o", name: "perf_26w100o" },
    { value: "perf_52w75u", name: "perf_52w75u" },
    { value: "perf_52w50u", name: "perf_52w50u" },
    { value: "perf_52w30u", name: "perf_52w30u" },
    { value: "perf_52w10u", name: "perf_52w10u" },
    { value: "perf_52wdown", name: "perf_52wdown" },
    { value: "perf_52wup", name: "perf_52wup" },
    { value: "perf_52w10o", name: "perf_52w10o" },
    { value: "perf_52w50o", name: "perf_52w50o" },
    { value: "perf_52w100o", name: "perf_52w100o" },
    { value: "perf_52w300o", name: "perf_52w300o" },
    { value: "perf_52w500o", name: "perf_52w500o" },
    { value: "perf_ytd75u", name: "perf_ytd75u" },
    { value: "perf_ytd50u", name: "perf_ytd50u" },
    { value: "perf_ytd30u", name: "perf_ytd30u" },
    { value: "perf_ytd10u", name: "perf_ytd10u" },
    { value: "perf_ytddown", name: "perf_ytddown" },
    { value: "perf_ytdup", name: "perf_ytdup" },
    { value: "perf_ytd10o", name: "perf_ytd10o" },
    { value: "perf_ytd30o", name: "perf_ytd30o" },
    { value: "perf_ytd50o", name: "perf_ytd50o" },
    { value: "perf_ytd100o", name: "perf_ytd100o" },
  ],
  pe: [
    { name: "any", value: "any" },
    { value: "pe_low", name: "pe_low" },
    { value: "pe_profitable", name: "pe_profitable" },
    { value: "pe_high", name: "pe_high" },
    { value: "pe_u5", name: "pe_u5" },
    { value: "pe_u10", name: "pe_u10" },
    { value: "pe_u15", name: "pe_u15" },
    { value: "pe_u20", name: "pe_u20" },
    { value: "pe_u30", name: "pe_u30" },
    { value: "pe_u40", name: "pe_u40" },
    { value: "pe_u50", name: "pe_u50" },
    { value: "pe_o5", name: "pe_o5" },
    { value: "pe_o10", name: "pe_o10" },
    { value: "pe_o15", name: "pe_o15" },
    { value: "pe_o20", name: "pe_o20" },
    { value: "pe_o30", name: "pe_o30" },
    { value: "pe_o40", name: "pe_o40" },
    { value: "pe_o50", name: "pe_o50" },
  ],
  peg: [
    { name: "any", value: "any" },
    { value: "peg_low", name: "peg_low" },
    { value: "peg_high", name: "peg_high" },
    { value: "peg_u1", name: "peg_u1" },
    { value: "peg_u2", name: "peg_u2" },
    { value: "peg_u3", name: "peg_u3" },
    { value: "peg_o1", name: "peg_o1" },
    { value: "peg_o2", name: "peg_o2" },
    { value: "peg_o3", name: "peg_o3" },
  ],
  pb: [
    { name: "any", value: "any" },
    { value: "pb_low", name: "pb_low" },
    { value: "pb_high", name: "pb_high" },
    { value: "pb_u1", name: "pb_u1" },
    { value: "pb_u2", name: "pb_u2" },
    { value: "pb_u3", name: "pb_u3" },
    { value: "pb_u4", name: "pb_u4" },
    { value: "pb_u5", name: "pb_u5" },
    { value: "pb_u6", name: "pb_u6" },
    { value: "pb_u7", name: "pb_u7" },
    { value: "pb_u8", name: "pb_u8" },
    { value: "pb_u9", name: "pb_u9" },
    { value: "pb_u10", name: "pb_u10" },
    { value: "pb_o1", name: "pb_o1" },
    { value: "pb_o2", name: "pb_o2" },
    { value: "pb_o3", name: "pb_o3" },
    { value: "pb_o4", name: "pb_o4" },
    { value: "pb_o5", name: "pb_o5" },
    { value: "pb_o6", name: "pb_o6" },
    { value: "pb_o7", name: "pb_o7" },
    { value: "pb_o8", name: "pb_o8" },
    { value: "pb_o9", name: "pb_o9" },
    { value: "pb_o10", name: "pb_o10" },
  ],
  ps: [
    { name: "any", value: "any" },
    { value: "ps_low", name: "ps_low" },
    { value: "ps_high", name: "ps_high" },
    { value: "ps_u1", name: "ps_u1" },
    { value: "ps_u2", name: "ps_u2" },
    { value: "ps_u3", name: "ps_u3" },
    { value: "ps_u4", name: "ps_u4" },
    { value: "ps_u5", name: "ps_u5" },
    { value: "ps_u6", name: "ps_u6" },
    { value: "ps_u7", name: "ps_u7" },
    { value: "ps_u8", name: "ps_u8" },
    { value: "ps_u9", name: "ps_u9" },
    { value: "ps_u10", name: "ps_u10" },
    { value: "ps_o1", name: "ps_o1" },
    { value: "ps_o2", name: "ps_o2" },
    { value: "ps_o3", name: "ps_o3" },
    { value: "ps_o4", name: "ps_o4" },
    { value: "ps_o5", name: "ps_o5" },
    { value: "ps_o6", name: "ps_o6" },
    { value: "ps_o7", name: "ps_o7" },
    { value: "ps_o8", name: "ps_o8" },
    { value: "ps_o9", name: "ps_o9" },
    { value: "ps_o10", name: "ps_o10" },
  ],
  quick: [
    { name: "any", value: "any" },
    { value: "quick_high", name: "quick_high" },
    { value: "quick_low", name: "quick_low" },
    { value: "quick_u1", name: "quick_u1" },
    { value: "quick_u0_5", name: "quick_u0_5" },
    { value: "quick_o0_5", name: "quick_o0_5" },
    { value: "quick_o1", name: "quick_o1" },
    { value: "quick_o1_5", name: "quick_o1_5" },
    { value: "quick_o2", name: "quick_o2" },
    { value: "quick_o3", name: "quick_o3" },
    { value: "quick_o4", name: "quick_o4" },
    { value: "quick_o5", name: "quick_o5" },
    { value: "quick_o10", name: "quick_o10" },
  ],
  current: [
    { name: "any", value: "any" },
    { value: "current_high", name: "current_high" },
    { value: "current_low", name: "current_low" },
    { value: "current_u1", name: "current_u1" },
    { value: "current_u0_5", name: "current_u0_5" },
    { value: "current_o0_5", name: "current_o0_5" },
    { value: "current_o1", name: "current_o1" },
    { value: "current_o1_5", name: "current_o1_5" },
    { value: "current_o2", name: "current_o2" },
    { value: "current_o3", name: "current_o3" },
    { value: "current_o4", name: "current_o4" },
    { value: "current_o5", name: "current_o5" },
    { value: "current_o10", name: "current_o10" },
  ],
  sector: [
    { name: "any", value: "any" },
    { value: "sector_realestate", name: "sector_realestate" },
    { value: "sector_health", name: "sector_health" },
    { value: "sector_energy", name: "sector_energy" },
    { value: "sector_utilities", name: "sector_utilities" },
    { value: "sector_cd", name: "sector_cd" },
    { value: "sector_fs", name: "sector_fs" },
    { value: "sector_coms", name: "sector_coms" },
    { value: "sector_cs", name: "sector_cs" },
    { value: "sector_bm", name: "sector_bm" },
    { value: "sector_i", name: "sector_i" },
    { value: "sector_cc", name: "sector_cc" },
    { value: "sector_t", name: "sector_t" },
  ],
  pricecash: [
    { name: "any", value: "any" },
    { value: "pricecash_low", name: "pricecash_low" },
    { value: "pricecash_high", name: "pricecash_high" },
    { value: "pricecash_u1", name: "pricecash_u1" },
    { value: "pricecash_u2", name: "pricecash_u2" },
    { value: "pricecash_u3", name: "pricecash_u3" },
    { value: "pricecash_u4", name: "pricecash_u4" },
    { value: "pricecash_u5", name: "pricecash_u5" },
    { value: "pricecash_u6", name: "pricecash_u6" },
    { value: "pricecash_u7", name: "pricecash_u7" },
    { value: "pricecash_u8", name: "pricecash_u8" },
    { value: "pricecash_u9", name: "pricecash_u9" },
    { value: "pricecash_u10", name: "pricecash_u10" },
    { value: "pricecash_o1", name: "pricecash_o1" },
    { value: "pricecash_o2", name: "pricecash_o2" },
    { value: "pricecash_o3", name: "pricecash_o3" },
    { value: "pricecash_o4", name: "pricecash_o4" },
    { value: "pricecash_o5", name: "pricecash_o5" },
    { value: "pricecash_o6", name: "pricecash_o6" },
    { value: "pricecash_o7", name: "pricecash_o7" },
    { value: "pricecash_o8", name: "pricecash_o8" },
    { value: "pricecash_o9", name: "pricecash_o9" },
    { value: "pricecash_o10", name: "pricecash_o10" },
    { value: "pricecash_o20", name: "pricecash_o20" },
    { value: "pricecash_o30", name: "pricecash_o30" },
    { value: "pricecash_o40", name: "pricecash_o40" },
    { value: "pricecash_o50", name: "pricecash_o50" },
  ],
  operatingMargin: [
    { name: "any", value: "any" },
    { value: "opmr_pos", name: "opmr_pos" },
    { value: "opmr_neg", name: "opmr_neg" },
    { value: "opmr_veryneg", name: "opmr_veryneg" },
    { value: "opmr_high", name: "opmr_high" },
    { value: "opmr_u90", name: "opmr_u90" },
    { value: "opmr_u80", name: "opmr_u80" },
    { value: "opmr_u70", name: "opmr_u70" },
    { value: "opmr_u60", name: "opmr_u60" },
    { value: "opmr_u50", name: "opmr_u50" },
    { value: "opmr_u40", name: "opmr_u40" },
    { value: "opmr_u30", name: "opmr_u30" },
    { value: "opmr_u20", name: "opmr_u20" },
    { value: "opmr_u10", name: "opmr_u10" },
    { value: "opmr_u_m10", name: "opmr_u_m10" },
    { value: "opmr_u_m30", name: "opmr_u_m30" },
    { value: "opmr_u_m50", name: "opmr_u_m50" },
    { value: "opmr_u_m100", name: "opmr_u_m100" },
    { value: "opmr_o10", name: "opmr_o10" },
    { value: "opmr_o20", name: "opmr_o20" },
    { value: "opmr_o30", name: "opmr_o30" },
    { value: "opmr_o40", name: "opmr_o40" },
    { value: "opmr_o50", name: "opmr_o50" },
    { value: "opmr_o60", name: "opmr_o60" },
    { value: "opmr_o70", name: "opmr_o70" },
    { value: "opmr_o80", name: "opmr_o80" },
    { value: "opmr_o90", name: "opmr_o90" },
  ],
  peHistoric: [
    { name: "any", value: "any" },
    { name: "pehistoric_below", value: "pehistoric_below" },
    { name: "pehistoric_above", value: "pehistoric_above" },
  ],
  pbHistoric: [
    { name: "any", value: "any" },
    { name: "pbhistoric_below", value: "pbhistoric_below" },
    { name: "pbhistoric_above", value: "pbhistoric_above" },
  ],
  psHistoric: [
    { name: "any", value: "any" },
    { name: "pshistoric_below", value: "pshistoric_below" },
    { name: "pshistoric_above", value: "pshistoric_above" },
  ],
  pfcf: [
    { name: "any", value: "any" },
    { value: "pfcf_low", name: "pfcf_low" },
    { value: "pfcf_high", name: "pfcf_high" },
    { value: "pfcf_u10", name: "pfcf_u10" },
    { value: "pfcf_u20", name: "pfcf_u20" },
    { value: "pfcf_u50", name: "pfcf_u50" },
    { value: "pfcf_u80", name: "pfcf_u80" },
    { value: "pfcf_u100", name: "pfcf_u100" },
    { value: "pfcf_o10", name: "pfcf_o10" },
    { value: "pfcf_o20", name: "pfcf_o20" },
    { value: "pfcf_o50", name: "pfcf_o50" },
    { value: "pfcf_o80", name: "pfcf_o80" },
    { value: "pfcf_o100", name: "pfcf_o100" },
  ],
  dividendYield: [
    { name: "any", value: "any" },
    { value: "divy_none", name: "divy_none" },
    { value: "divy_pos", name: "divy_pos" },
    { value: "divy_high", name: "divy_high" },
    { value: "divy_veryhigh", name: "divy_veryhigh" },
    { value: "divy_o1", name: "divy_o1" },
    { value: "divy_o2", name: "divy_o2" },
    { value: "divy_o3", name: "divy_o3" },
    { value: "divy_o4", name: "divy_o4" },
    { value: "divy_o5", name: "divy_o5" },
    { value: "divy_o6", name: "divy_o6" },
    { value: "divy_o7", name: "divy_o7" },
    { value: "divy_o8", name: "divy_o8" },
    { value: "divy_o9", name: "divy_o9" },
    { value: "divy_o10", name: "divy_o10" },
  ],
  payoutRatio: [
    { name: "any", value: "any" },
    { value: "payoutr_none", name: "payoutr_none" },
    { value: "payoutr_pos", name: "payoutr_pos" },
    { value: "payoutr_low", name: "payoutr_low" },
    { value: "payoutr_high", name: "payoutr_high" },
    { value: "payoutr_o10", name: "payoutr_o10" },
    { value: "payoutr_o20", name: "payoutr_o20" },
    { value: "payoutr_o50", name: "payoutr_o50" },
    { value: "payoutr_o80", name: "payoutr_o80" },
    { value: "payoutr_o100", name: "payoutr_o100" },
    { value: "payoutr_u10", name: "payoutr_u10" },
    { value: "payoutr_u20", name: "payoutr_u20" },
    { value: "payoutr_u50", name: "payoutr_u50" },
    { value: "payoutr_u80", name: "payoutr_u80" },
    { value: "payoutr_u100", name: "payoutr_u100" },
  ],
  netProfitMargin: [
    { name: "any", value: "any" },
    { value: "netprf_pos", name: "netprf_pos" },
    { value: "netprf_neg", name: "netprf_neg" },
    { value: "netprf_veryneg", name: "netprf_veryneg" },
    { value: "netprf_high", name: "netprf_high" },
    { value: "netprf_u90", name: "netprf_u90" },
    { value: "netprf_u80", name: "netprf_u80" },
    { value: "netprf_u50", name: "netprf_u50" },
    { value: "netprf_u30", name: "netprf_u30" },
    { value: "netprf_u10", name: "netprf_u10" },
    { value: "netprf_u5", name: "netprf_u5" },
    { value: "netprf_u_10", name: "netprf_u_10" },
    { value: "netprf_u_30", name: "netprf_u_30" },
    { value: "netprf_u_50", name: "netprf_u_50" },
    { value: "netprf_u_100", name: "netprf_u_100" },
    { value: "netprf_o10", name: "netprf_o10" },
    { value: "netprf_o30", name: "netprf_o30" },
    { value: "netprf_o40", name: "netprf_o40" },
    { value: "netprf_o50", name: "netprf_o50" },
    { value: "netprf_o80", name: "netprf_o80" },
    { value: "netprf_o90", name: "netprf_o90" },
  ],
  deRatio: [
    { name: "any", value: "any" },
    { value: "deratio_high", name: "deratio_high" },
    { value: "deratio_low", name: "deratio_low" },
    { value: "deratio_u1", name: "deratio_u1" },
    { value: "deratio_u0_9", name: "deratio_u0_9" },
    { value: "deratio_u0_8", name: "deratio_u0_8" },
    { value: "deratio_u0_7", name: "deratio_u0_7" },
    { value: "deratio_u0_6", name: "deratio_u0_6" },
    { value: "deratio_u0_5", name: "deratio_u0_5" },
    { value: "deratio_u0_4", name: "deratio_u0_4" },
    { value: "deratio_u0_3", name: "deratio_u0_3" },
    { value: "deratio_u0_2", name: "deratio_u0_2" },
    { value: "deratio_u0_1", name: "deratio_u0_1" },
    { value: "deratio_o0_1", name: "deratio_o0_1" },
    { value: "deratio_o0_2", name: "deratio_o0_2" },
    { value: "deratio_o0_3", name: "deratio_o0_3" },
    { value: "deratio_o0_4", name: "deratio_o0_4" },
    { value: "deratio_o0_5", name: "deratio_o0_5" },
    { value: "deratio_o0_6", name: "deratio_o0_6" },
    { value: "deratio_o0_7", name: "deratio_o0_7" },
    { value: "deratio_o0_8", name: "deratio_o0_8" },
    { value: "deratio_o0_9", name: "deratio_o0_9" },
    { value: "deratio_o1", name: "deratio_o1" },
  ],
  grossMargin: [
    { name: "any", value: "any" },
    { value: "grossm_pos", name: "grossm_pos" },
    { value: "grossm_neg", name: "grossm_neg" },
    { value: "grossm_u90", name: "grossm_u90" },
    { value: "grossm_u70", name: "grossm_u70" },
    { value: "grossm_u50", name: "grossm_u50" },
    { value: "grossm_u30", name: "grossm_u30" },
    { value: "grossm_u10", name: "grossm_u10" },
    { value: "grossm_u5", name: "grossm_u5" },
    { value: "grossm_u_10", name: "grossm_u_10" },
    { value: "grossm_u_30", name: "grossm_u_30" },
    { value: "grossm_u_50", name: "grossm_u_50" },
    { value: "grossm_u_70", name: "grossm_u_70" },
    { value: "grossm_u_100", name: "grossm_u_100" },
    { value: "grossm_o0", name: "grossm_o0" },
    { value: "grossm_o10", name: "grossm_o10" },
    { value: "grossm_o30", name: "grossm_o30" },
    { value: "grossm_o50", name: "grossm_o50" },
    { value: "grossm_o70", name: "grossm_o70" },
    { value: "grossm_o90", name: "grossm_o90" },
  ],
  ltdc: [
    { name: "any", value: "any" },
    { value: "ltdc_high", name: "ltdc_high" },
    { value: "ltdc_low", name: "ltdc_low" },
    { value: "ltdc_u1", name: "ltdc_u1" },
    { value: "ltdc_u0_9", name: "ltdc_u0_9" },
    { value: "ltdc_u0_8", name: "ltdc_u0_8" },
    { value: "ltdc_u0_7", name: "ltdc_u0_7" },
    { value: "ltdc_u0_6", name: "ltdc_u0_6" },
    { value: "ltdc_u0_5", name: "ltdc_u0_5" },
    { value: "ltdc_u0_4", name: "ltdc_u0_4" },
    { value: "ltdc_u0_3", name: "ltdc_u0_3" },
    { value: "ltdc_u0_2", name: "ltdc_u0_2" },
    { value: "ltdc_u0_1", name: "ltdc_u0_1" },
    { value: "ltdc_o0_1", name: "ltdc_o0_1" },
    { value: "ltdc_o0_2", name: "ltdc_o0_2" },
    { value: "ltdc_o0_3", name: "ltdc_o0_3" },
    { value: "ltdc_o0_4", name: "ltdc_o0_4" },
    { value: "ltdc_o0_5", name: "ltdc_o0_5" },
    { value: "ltdc_o0_6", name: "ltdc_o0_6" },
    { value: "ltdc_o0_7", name: "ltdc_o0_7" },
    { value: "ltdc_o0_8", name: "ltdc_o0_8" },
    { value: "ltdc_o0_9", name: "ltdc_o0_9" },
    { value: "ltdc_o1", name: "ltdc_o1" },
  ],
  revgrow: [
    { name: "any", value: "any" },
    { value: "revgrow_neg", name: "revgrow_neg" },
    { value: "revgrow_pos", name: "revgrow_pos" },
    { value: "revgrow_u10", name: "revgrow_u10" },
    { value: "revgrow_u20", name: "revgrow_u20" },
    { value: "revgrow_u50", name: "revgrow_u50" },
    { value: "revgrow_u100", name: "revgrow_u100" },
    { value: "revgrow_o10", name: "revgrow_o10" },
    { value: "revgrow_o20", name: "revgrow_o20" },
    { value: "revgrow_o50", name: "revgrow_o50" },
    { value: "revgrow_o100", name: "revgrow_o100" },
  ],
  netincgrow: [
    { name: "any", value: "any" },
    { value: "netincgrow_neg", name: "netincgrow_neg" },
    { value: "netincgrow_pos", name: "netincgrow_pos" },
    { value: "netincgrow_u10", name: "netincgrow_u10" },
    { value: "netincgrow_u20", name: "netincgrow_u20" },
    { value: "netincgrow_u50", name: "netincgrow_u50" },
    { value: "netincgrow_u100", name: "netincgrow_u100" },
    { value: "netincgrow_o10", name: "netincgrow_o10" },
    { value: "netincgrow_o20", name: "netincgrow_o20" },
    { value: "netincgrow_o50", name: "netincgrow_o50" },
    { value: "netincgrow_o100", name: "netincgrow_o100" },
  ],
  revgrowavg: [
    { name: "any", value: "any" },
    { value: "revgrowavg_neg", name: "revgrowavg_neg" },
    { value: "revgrowavg_pos", name: "revgrowavg_pos" },
    { value: "revgrowavg_u10", name: "revgrowavg_u10" },
    { value: "revgrowavg_u20", name: "revgrowavg_u20" },
    { value: "revgrowavg_u50", name: "revgrowavg_u50" },
    { value: "revgrowavg_u100", name: "revgrowavg_u100" },
    { value: "revgrowavg_o10", name: "revgrowavg_o10" },
    { value: "revgrowavg_o20", name: "revgrowavg_o20" },
    { value: "revgrowavg_o50", name: "revgrowavg_o50" },
    { value: "revgrowavg_o100", name: "revgrowavg_o100" },
  ],
  industry: [
    { name: "any", value: "any" },
    { name: "ind_aginputs", value: "ind_aginputs" },
    { name: "ind_indpollctrl", value: "ind_indpollctrl" },
    { name: "ind_indmaterials", value: "ind_indmaterials" },
    { name: "ind_autorv", value: "ind_autorv" },
    { name: "ind_discstores", value: "ind_discstores" },
    { name: "ind_insdiv", value: "ind_insdiv" },
    { name: "ind_entertainment", value: "ind_entertainment" },
    { name: "ind_reitdiv", value: "ind_reitdiv" },
    { name: "ind_medicaldist", value: "ind_medicaldist" },
    { name: "ind_softinfra", value: "ind_softinfra" },
    { name: "ind_softapp", value: "ind_softapp" },
    { name: "ind_electricaleqp", value: "ind_electricaleqp" },
    { name: "ind_astmgmtglobal", value: "ind_astmgmtglobal" },
    { name: "ind_indpower", value: "ind_indpower" },
    { name: "ind_oilgasdrill", value: "ind_oilgasdrill" },
    { name: "ind_leisure", value: "ind_leisure" },
    { name: "ind_medicaldev", value: "ind_medicaldev" },
    { name: "ind_coal", value: "ind_coal" },
    { name: "ind_edu", value: "ind_edu" },
    { name: "ind_reitind", value: "ind_reitind" },
    { name: "ind_autoparts", value: "ind_autoparts" },
    { name: "ind_metalfab", value: "ind_metalfab" },
    { name: "ind_fincredit", value: "ind_fincredit" },
    { name: "ind_broadcasting", value: "ind_broadcasting" },
    { name: "ind_medcarefac", value: "ind_medcarefac" },
    { name: "ind_chemspecialty", value: "ind_chemspecialty" },
    { name: "ind_astmgmt", value: "ind_astmgmt" },
    { name: "ind_insreins", value: "ind_insreins" },
    { name: "ind_travellodging", value: "ind_travellodging" },
    { name: "ind_findiv", value: "ind_findiv" },
    { name: "ind_banks", value: "ind_banks" },
    { name: "ind_bevalcoholic", value: "ind_bevalcoholic" },
    { name: "ind_hardwareeqp", value: "ind_hardwareeqp" },
    { name: "ind_inddist", value: "ind_inddist" },
    { name: "ind_constrmat", value: "ind_constrmat" },
    { name: "ind_fooddist", value: "ind_fooddist" },
    { name: "ind_bevwines", value: "ind_bevwines" },
    { name: "ind_furnishings", value: "ind_furnishings" },
    { name: "ind_silver", value: "ind_silver" },
    { name: "ind_specialtybiz", value: "ind_specialtybiz" },
    { name: "ind_apparelmanu", value: "ind_apparelmanu" },
    { name: "ind_publishing", value: "ind_publishing" },
    { name: "ind_finmortgages", value: "ind_finmortgages" },
    { name: "ind_railroads", value: "ind_railroads" },
    { name: "ind_insprop", value: "ind_insprop" },
    { name: "ind_telco", value: "ind_telco" },
    { name: "ind_reitretail", value: "ind_reitretail" },
    { name: "ind_internetcontent", value: "ind_internetcontent" },
    { name: "ind_indinfrastructure", value: "ind_indinfrastructure" },
    { name: "ind_regwater", value: "ind_regwater" },
    { name: "ind_packaging", value: "ind_packaging" },
    { name: "ind_realeservices", value: "ind_realeservices" },
    { name: "ind_reddiversified", value: "ind_reddiversified" },
    { name: "ind_renewutilities", value: "ind_renewutilities" },
    { name: "ind_preciousmetals", value: "ind_preciousmetals" },
    { name: "ind_autodealerships", value: "ind_autodealerships" },
    { name: "ind_foodconf", value: "ind_foodconf" },
    { name: "ind_toolsmfg", value: "ind_toolsmfg" },
    { name: "ind_findata", value: "ind_findata" },
    { name: "ind_adagencies", value: "ind_adagencies" },
    { name: "ind_luxurygoods", value: "ind_luxurygoods" },
    { name: "ind_householdprod", value: "ind_householdprod" },
    { name: "ind_reitresidential", value: "ind_reitresidential" },
    { name: "ind_deptstores", value: "ind_deptstores" },
    { name: "ind_personalprod", value: "ind_personalprod" },
    { name: "ind_paperlumber", value: "ind_paperlumber" },
    { name: "ind_staffing", value: "ind_staffing" },
    { name: "ind_resconstr", value: "ind_resconstr" },
    { name: "ind_healthcare", value: "ind_healthcare" },
    { name: "ind_construction", value: "ind_construction" },
    { name: "ind_specialtyretail", value: "ind_specialtyretail" },
    { name: "ind_mediaentertain", value: "ind_mediaentertain" },
    { name: "ind_realdev", value: "ind_realdev" },
    { name: "ind_astmgmtincome", value: "ind_astmgmtincome" },
    { name: "ind_engconst", value: "ind_engconst" },
    { name: "ind_gambling", value: "ind_gambling" },
    { name: "ind_reitoffice", value: "ind_reitoffice" },
    { name: "ind_finservices", value: "ind_finservices" },
    { name: "ind_drugmanugen", value: "ind_drugmanugen" },
    { name: "ind_homeimprovement", value: "ind_homeimprovement" },
    { name: "ind_steel", value: "ind_steel" },
    { name: "ind_regionalbanks", value: "ind_regionalbanks" },
    { name: "ind_agfarmproducts", value: "ind_agfarmproducts" },
    { name: "ind_conglomerates", value: "ind_conglomerates" },
    { name: "ind_medhealthcareplans", value: "ind_medhealthcareplans" },
    { name: "ind_chemicals", value: "ind_chemicals" },
    { name: "ind_oilmidstream", value: "ind_oilmidstream" },
    { name: "ind_oilexp", value: "ind_oilexp" },
    { name: "ind_computerhardware", value: "ind_computerhardware" },
    { name: "ind_banksdiversified", value: "ind_banksdiversified" },
    { name: "ind_restaurants", value: "ind_restaurants" },
    { name: "ind_oilintegrated", value: "ind_oilintegrated" },
    { name: "ind_divutilities", value: "ind_divutilities" },
    { name: "ind_reithotel", value: "ind_reithotel" },
    { name: "ind_gaming", value: "ind_gaming" },
    { name: "ind_medpharma", value: "ind_medpharma" },
    { name: "ind_grocerystores", value: "ind_grocerystores" },
    { name: "ind_wastemgmt", value: "ind_wastemgmt" },
    { name: "ind_reggas", value: "ind_reggas" },
    { name: "ind_trucking", value: "ind_trucking" },
    { name: "ind_investbanking", value: "ind_investbanking" },
    { name: "ind_automanufact", value: "ind_automanufact" },
    { name: "ind_reitmortgage", value: "ind_reitmortgage" },
    { name: "ind_biotech", value: "ind_biotech" },
    { name: "ind_techdistrib", value: "ind_techdistrib" },
    { name: "ind_bizequip", value: "ind_bizequip" },
    { name: "ind_apparelfoot", value: "ind_apparelfoot" },
    { name: "ind_medicalspec", value: "ind_medicalspec" },
    { name: "ind_apparelretail", value: "ind_apparelretail" },
    { name: "ind_regelectric", value: "ind_regelectric" },
    { name: "ind_finconglomerates", value: "ind_finconglomerates" },
    { name: "ind_oilrefining", value: "ind_oilrefining" },
    { name: "ind_reitspecialty", value: "ind_reitspecialty" },
    { name: "ind_agmachinery", value: "ind_agmachinery" },
    { name: "ind_packagedfoods", value: "ind_packagedfoods" },
    { name: "ind_reithealthcare", value: "ind_reithealthcare" },
    { name: "ind_solar", value: "ind_solar" },
    { name: "ind_copper", value: "ind_copper" },
    { name: "ind_gold", value: "ind_gold" },
    { name: "ind_medicalsupp", value: "ind_medicalsupp" },
    { name: "ind_insurancebrokers", value: "ind_insurancebrokers" },
    { name: "ind_security", value: "ind_security" },
    { name: "ind_tobacco", value: "ind_tobacco" },
    { name: "ind_fincapmarkets", value: "ind_fincapmarkets" },
    { name: "ind_mfgmisc", value: "ind_mfgmisc" },
    { name: "ind_uranium", value: "ind_uranium" },
    { name: "ind_insurancelife", value: "ind_insurancelife" },
    { name: "ind_indmachinery", value: "ind_indmachinery" },
    { name: "ind_shellcompanies", value: "ind_shellcompanies" },
    { name: "ind_consumelectronics", value: "ind_consumelectronics" },
    { name: "ind_medicaldiag", value: "ind_medicaldiag" },
    { name: "ind_oilequip", value: "ind_oilequip" },
    { name: "ind_commequip", value: "ind_commequip" },
    { name: "ind_aerospace", value: "ind_aerospace" },
    { name: "ind_travelservices", value: "ind_travelservices" },
    { name: "ind_drugmanuspecialty", value: "ind_drugmanuspecialty" },
    { name: "ind_aluminum", value: "ind_aluminum" },
    { name: "ind_itservices", value: "ind_itservices" },
    { name: "ind_medhealthinfo", value: "ind_medhealthinfo" },
    { name: "ind_bevnonalcoholic", value: "ind_bevnonalcoholic" },
    { name: "ind_consulting", value: "ind_consulting" },
    { name: "ind_rentleasing", value: "ind_rentleasing" },
    { name: "ind_marineshipping", value: "ind_marineshipping" },
    { name: "ind_software", value: "ind_software" },
    { name: "ind_astmgmtbonds", value: "ind_astmgmtbonds" },
    { name: "ind_freightlogistics", value: "ind_freightlogistics" },
    { name: "ind_insspec", value: "ind_insspec" },
    { name: "ind_semiconductors", value: "ind_semiconductors" },
    { name: "ind_airlines", value: "ind_airlines" },
  ],
};

// export const FORUM_CATEGORIES = [
//   { name: "Category", value: 0 },
//   { name: "AskmeAnything", value: "Ask me Anything" },
//   { name: "AITech", value: "AI Tech" },
//   { name: "BasicMaterials", value: "Basic Materials" },
//   { name: "Biotech", value: "Biotech" },
//   { name: "Bond", value: "Bond" },
//   { name: "ClosedEndFunds", value: "Closed End Funds" },
//   { name: "Commodities", value: "Commodities" },
//   { name: "CommunicationServices", value: "Communication Services" },
//   { name: "Consumer", value: "Consumer" },
//   { name: "ConsumerStaples", value: "Consumer Staples" },
//   { name: "Cryptocurrency", value: "Cryptocurrency" },
//   { name: "DividendIdeas", value: "Dividend Ideas" },
//   { name: "DividendQuickPick", value: "Dividend Quick Pick" },
//   { name: "DividendStrategy", value: "Dividend Strategy" },
//   { name: "Dividend", value: "Dividend" },
//   { name: "EditorsPicks", value: "Editors Picks" },
//   { name: "ElectricVehicle", value: "Electric Vehicle" },
//   { name: "Energy", value: "Energy" },
//   { name: "ETFAnalysis", value: "ETF Analysis" },
//   { name: "ETFsAndFunds", value: "ETFs & Funds" },
//   { name: "FinancialAdvisor", value: "Financial Advisor" },
//   { name: "Financials", value: "Financials" },
//   { name: "FixedIncome", value: "Fixed Income" },
//   { name: "Forex", value: "Forex" },
//   { name: "GoldAndPreciousMetals", value: "Gold & Precious Metals" },
//   { name: "Growth", value: "Growth" },
//   { name: "Healthcare", value: "Healthcare" },
//   { name: "Industrial", value: "Industrial" },
//   { name: "InterestRates", value: "Interest Rates" },
//   { name: "InvestingStrategy", value: "Investing Strategy" },
//   { name: "IPOs", value: "IPOs" },
//   { name: "LongIdeas", value: "Long Ideas" },
//   { name: "Magnificent7", value: "Magnificent 7" },
//   { name: "MarketOutlook", value: "Market Outlook" },
//   { name: "MicroCaps", value: "Micro-Caps" },
//   { name: "MutualFunds", value: "Mutual Funds" },
//   { name: "Podcast", value: "Podcast" },
//   { name: "PortfolioStrategy", value: "Portfolio Strategy" },
//   { name: "QuickPicks", value: "Quick Picks" },
//   { name: "RealEstate", value: "Real Estate" },
//   { name: "REITs", value: "REITs" },
//   { name: "Retirement", value: "Retirement" },
//   { name: "SmallCaps", value: "Small-Caps" },
//   { name: "StockIdeas", value: "Stock Ideas" },
//   { name: "Tech", value: "Tech" },
//   { name: "TrendingAnalysis", value: "Trending Analysis" },
//   { name: "USEconomy", value: "US Economy" },
//   { name: "UndercoveredStocks", value: "Undercovered Stocks" },
//   { name: "Utilities", value: "Utilities" },
//   { name: "Value", value: "Value" },
//   { name: "WeightLoss", value: "Weight Loss" },
//   { name: "GeneralInvesting", value: "General Investing" },
//   { name: "Adulting", value: "Adulting" },
//   { name: "Insurance", value: "Insurance" },
//   { name: "SavingHacks", value: "Saving Hacks" },
//   { name: "Budgeting", value: "Budgeting" },
//   { name: "CreditCards", value: "Credit Cards" },
//   { name: "Career", value: "Career" },
//   { name: "Watches", value: "Watches" },
// ];

export const FORUM_CATEGORIES = [
  { name: "Category", value: 0 },
  { name: "Stocks", value: "Stocks" },
  { name: "AssetClasses", value: "Asset Classes" },
  { name: "Analysis", value: "Analysis" },
  { name: "Others", value: "Others" },
];

export const FORUM_SUBCATEGORIES = {
  Stocks: [
    { name: "SubCategory", value: 0 },
    { name: "AskmeAnything", value: "Ask me Anything" },
    { name: "AITech", value: "AI Tech" },
    { name: "BasicMaterials", value: "Basic Materials" },
    { name: "Biotech", value: "Biotech" },
    { name: "CommunicationServices", value: "Communication Services" },
    { name: "Consumer", value: "Consumer" },
    { name: "ConsumerStaples", value: "Consumer Staples" },
    { name: "ElectricVehicle", value: "Electric Vehicle" },
    { name: "Energy", value: "Energy" },
    { name: "Financials", value: "Financials" },
    { name: "Growth", value: "Growth" },
    { name: "Healthcare", value: "Healthcare" },
    { name: "Industrial", value: "Industrial" },
    { name: "RealEstate", value: "Real Estate" },
    { name: "REITs", value: "REITs" },
    { name: "Utilities", value: "Utilities" },
    { name: "WeightLoss", value: "Weight Loss" },
    { name: "Tech", value: "Tech" },
    { name: "Dividend", value: "Dividend" },
    { name: "IPOs", value: "IPOs" },
    { name: "Magnificent7", value: "Magnificent 7" },
    { name: "MicroCaps", value: "Micro-Caps" },
    { name: "SmallCaps", value: "Small-Caps" },
  ],
  "Asset Classes": [
    { name: "SubCategory", value: 0 },
    { name: "AskmeAnything", value: "Ask me Anything" },
    { name: "Bonds", value: "Bonds" },
    { name: "Commodities", value: "Commodities" },
    { name: "ClosedEndFunds", value: "Closed End Funds" },
    { name: "Cryptocurrency", value: "Cryptocurrency" },
    { name: "ETFsAndFunds", value: "ETFs and Funds" },
    { name: "FixedIncome", value: "Fixed Income" },
    { name: "Forex", value: "Forex" },
    { name: "GoldAndPreciousMetals", value: "Gold and Precious Metals" },
    { name: "InterestRates", value: "Interest Rates" },
    { name: "MutualFunds", value: "Mutual Funds" },
  ],
  Analysis: [
    { name: "SubCategory", value: 0 },
    { name: "AskmeAnything", value: "Ask me Anything" },
    { name: "DividendIdeas", value: "Dividend Ideas" },
    { name: "DividendQuickPick", value: "Dividend Quick Pick" },
    { name: "DividendStrategy", value: "Dividend Strategy" },
    { name: "EditorsPicks", value: "Editors Picks" },
    { name: "ETFAnalysis", value: "ETF Analysis" },
    { name: "InvestingStrategy", value: "Investing Strategy" },
    { name: "TradingStrategy", value: "Trading Strategy" },
    { name: "LongIdeas", value: "Long Ideas" },
    { name: "PortfolioStrategy", value: "Portfolio Strategy" },
    { name: "StockIdeas", value: "Stock Ideas" },
    { name: "TrendingAnalysis", value: "Trending Analysis" },
    { name: "UndercoveredStocks", value: "Undercovered Stocks" },
    { name: "QuickPicks", value: "Quick Picks" },
    { name: "Value", value: "Value" },
    { name: "MarketOutlook", value: "Market Outlook" },
    { name: "USEconomy", value: "US Economy" },
  ],
  Others:[
    { name: "SubCategory", value: 0 },
    { name: "AskmeAnything", value: "Ask me Anything" },
    { name: "FinancialAdvisor", value: "Financial Advisor" },
    { name: "Podcast", value: "Podcast and Newsletters" },
    { name: "Retirement", value: "Retirement" },
    { name: "Adulting", value: "Adulting" },
    { name: "SavingHacks", value: "Saving Hacks" },
    { name: "Career", value: "Career" },
    { name: "Budgeting", value: "Budgeting" },
    { name: "CreditCards", value: "Credit Cards" },
    { name: "Insurance", value: "Insurance" },
    { name: "Watches", value: "Watches" },
    { name: "MoneyHacks", value: "Money Hacks" },
    { name: "ContentCreators", value: "Content Creators" },
    { name: "ToolsResources", value: "Tools and Resources" },
  ],
};
