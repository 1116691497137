export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol)
    : "0";
};

export const stringFormatter = (prefix = "", string, postfix = "") => {
  console.log(string);
  if (string === undefined || isNaN(parseFloat(string))) {
    return "-";
  }
  return `${prefix}${string}${postfix}`;
};

export const getRandomIDPrefix = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

export const dataExists = (data) => {
  return data === "" || data === undefined || data === null ? false : true;
};

export const formatDate = (inputDate) => {
  let date = new Date(inputDate);
  // const day = date.getDate().toString().padStart(2, "0");
  // const month = date.toLocaleString("default", { month: "short" });
  // const year = date.getFullYear();

  let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  let month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  return `${day}-${month}-${year}`;
};

export const formatDateYYYYMMDD = (inputDate) => {
  let currentDate = new Date(inputDate);
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  let day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getMondayOfDate = (date) => {
  let monday = new Date(date);
  let day = monday.getDay();
  let diff = (day === 0 ? -6 : 1) - day;
  monday.setDate(monday.getDate() + diff);
  return monday;
};

export const dateDifferenceInDays = (date1, date2) => {
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();
  const differenceInMilliseconds = Math.abs(time1 - time2);
  const daysDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  return Math.floor(daysDifference);
};

export const getLastElements = (arr, size) => {
  return arr.length <= size ? arr : arr.slice(-size);
};
