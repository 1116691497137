import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";

const PaginatedList = ({
  lang,
  mode,
  user,
  category,
  subcategory,
  username,
  title,
  following,
  ticker,
  trending,
  pageSize = 5,
  hideUsername = false,
}) => {
  const [loggedin, setLoggedIn] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState(null);

  const loadData = async () => {
    // if (dataExists(user)) {
    let fullUrl = `${apiEndPoints.getQuestions}/?page=${pageNumber}&page_size=${pageSize}&`;
    let headers = {
      "Content-Type": "application/json",
    };
    if (dataExists(user)) {
      headers["Authorization"] = `Bearer ${user?.token}`;
    }

    if (dataExists(category) && category !== "0") {
      fullUrl += "category=" + category + "&";
    }
    if (dataExists(subcategory) && subcategory !== "0") {
      fullUrl += "subcategory=" + subcategory + "&";
    }
    if (dataExists(username)) {
      fullUrl += "username=" + username + "&";
    }
    if (dataExists(ticker)) {
      fullUrl += "ticker=" + ticker + "&";
    }
    if (dataExists(trending)) {
      fullUrl += "trending=" + trending + "&";
    }
    if (dataExists(following)) {
      fullUrl += "following=" + following;
    }

    WebService.get(fullUrl, { headers }).then((response) => {
      let _data = response?.data;
      if (_data) {
        setData(_data?.results);
        setTotalQuestions(_data?.count);
        let _totalPages = Math.ceil(_data?.count / pageSize);
        setTotalPages(_totalPages);
      }
    });
    // }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [pageNumber, category, username, following, ticker, subcategory]);

  return (
    <>
      <div className="bd-highlight forum-container mt-0">
        <div className="screener-title mb-2">{title}</div>
        <div
          className={`forum-list-container ${
            mode === "dark" ? "forum-dark" : "forum-light"
          }`}
        >
          {data !== null && data?.length > 0 ? (
            data.map((question) => {
              return (
                <a
                  key={question.id}
                  href={`/forum/${question.slug}`}
                  className="text-body text-decoration-none"
                >
                  <div
                    className={`forum-list mb-2 ${
                      mode === "dark" ? "background-dark" : "background-light"
                    }`}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="forum-list-title-display">
                        {question.formatted_title}
                        <div className="d-flex forum-list-title">
                          <div className="align-items-center me-3 hideon-mobile">
                            <i className="bi bi-circle-fill forum-list-icon"></i>
                            {localString[lang]["comments"]} (
                            {question.total_comments})
                          </div>
                          <div className="align-items-center me-3 hideon-mobile">
                            <i className="bi bi-circle-fill forum-list-icon"></i>
                            {formatDate(question.created_at)}
                          </div>
                          {!hideUsername && (
                            <div className="align-items-center me-3">
                              <i className="bi bi-circle-fill forum-list-icon"></i>
                              {question.username}
                            </div>
                          )}
                          <div className="align-items-center me-3">
                            <i className="bi bi-circle-fill forum-list-icon"></i>
                            {question.category}
                          </div>
                          <div className="align-items-center me-3 only-mobile">
                            <i className="bi bi-hand-thumbs-up-fill me-1"></i>(
                            {question.upvote_count})
                          </div>
                          <div className="align-items-center me-3 only-mobile">
                            <i className="bi bi-hand-thumbs-down-fill me-1"></i>
                            ({question.downvote_count})
                          </div>
                        </div>
                      </div>
                      <div className="forum-list-index w-25 text-end forum-list-upvotes-display">
                        {question.ticker && (
                          <Button
                            className="me-2"
                            variant="secondary"
                            size="sm"
                          >
                            {question.ticker}
                          </Button>
                        )}
                        <span className="chart-numbers-change-green me-2">
                          <i className="bi bi-hand-thumbs-up-fill me-1"></i>(
                          {question.upvote_count})
                        </span>
                        <span className="chart-numbers-change-red me-3">
                          <i className="bi bi-hand-thumbs-down-fill me-1"></i>(
                          {question.downvote_count})
                        </span>
                        {/* <Button variant="light" size="sm">
                          <i className="bi bi-arrow-up-short"></i>
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </a>
              );
            })
          ) : (
            <div className="mb-3 text-center text-body-tertiary mt-2">
              {localString[lang]["emptyRow"]}
            </div>
          )}

          <div className="text-center">
            <PaginationComponent
              itemsCount={totalQuestions}
              itemsPerPage={pageSize}
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginatedList;
