import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";
import ForumComments from "./ForumComments";
import TopContributers from "./TopContributers";
import Parser from "html-react-parser";
import ForumNestedComments from "./ForumNestedComments";

const ForumNestedCommentList = ({
  lang,
  mode,
  user,
  setForceShowLogin,
  questionDetails,
  setLoadNestedComments,
  parentComment,
  responseCount,
  showCommentBox
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);
  const [loggedin, setLoggedin] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalComments, setTotalComments] = useState(0);
  const [userComment, setUserComment] = useState("");
  const [allComments, setAllComments] = useState([]);

  // const [upvoted, setUpvoted] = useState(false);
  // const [downvoted, setDownvoted] = useState(false);
  // const [upvoteCount, setUpvoteCount] = useState(0);
  // const [downvoteCount, setDownvoteCount] = useState(0);
  // const [userVotetype, setUserVotetype] = useState(null);

  const navigate = useNavigate();
  const { slug } = useParams();

  const loadCommentsData = async () => {
    let fullUrl = `${apiEndPoints.getNestedAnswers}/?parent_id=${parentComment?.id}`;
    let headers = {
      "Content-Type": "application/json",
    };
    if (dataExists(user)) {
      headers["Authorization"] = `Bearer ${user?.token}`;
    }

    WebService.get(fullUrl, { headers }).then((response) => {
      let _data = response?.data;
      console.log("_data", _data)
      if (_data) {
        setAllComments(_data);
      }
    });
  };

  useEffect(() => {
    loadCommentsData();
    // loadQuestionData();
    if (dataExists(user)) {
      setLoggedin(true);
    }
  }, [questionDetails, responseCount]);

  // useEffect(() => {
  //   if (questionDetails) {
  //     if (questionDetails) {
  //       setDownvoteCount(questionDetails.downvote_count);
  //       setUpvoteCount(questionDetails.upvote_count);
  //       setUserVotetype(questionDetails?.user_votetype);
  //     }

  //     if (questionDetails?.user_votetype === true) {
  //       setUpvoted(true);
  //     } else if (questionDetails?.user_votetype === false) {
  //       setDownvoted(true);
  //     } else {
  //       setUpvoted(false);
  //       setDownvoted(false);
  //     }

  //     loadCommentsData();
  //   }
  // }, [questionDetails, pageNumber]);

  console.log("allComments --", allComments);
  return (
    <>
      {questionDetails && (
        <>
          {/* User Comments */}
          {allComments !== null &&
            allComments?.length > 0 &&
            allComments.map((comment) => (
              <ForumNestedComments
                key={comment?.id}
                comment={comment}
                user={user}
                lang={lang}
                mode={mode}
                questionDetails={questionDetails}
                parentComment={parentComment}
                setForceShowLogin={setForceShowLogin}
                setLoadNestedComments={setLoadNestedComments}
                showCommentBox={showCommentBox}
              />
            ))}
          <div className="d-flex pt-3">
            <p
              onClick={() => {
                setLoadNestedComments(false);
              }}
              className="text-info-emphasis cursor-pointer forum-list-title"
            >
              {localString[lang]['hideResponses']}
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default ForumNestedCommentList;
