import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";

const TopContributers = ({
  lang,
  mode,
  user,
  category,
  username,
  title,
  pageSize = 5,
  setForceShowLogin,
}) => {
  const [loggedin, setLoggedIn] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);

  const loadData = async () => {
    let fullUrl = `${apiEndPoints.topContributers}/`;
    let headers = {
      "Content-Type": "application/json",
    };

    if (dataExists(user)) {
      headers["Authorization"] = `Bearer ${user?.token}`;
    }

    WebService.get(fullUrl, { headers }).then((response) => {
      let _data = response?.data;
      if (_data) {
        setData(_data);
      }
    });
  };

  const follow = async (_data) => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.follow}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        user: _data.id,
      };
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        loadData();
        // } else {
        //   setValidUsername(false);
      }
    }
  };
  const unfollow = async (_data) => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.unFollow}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        user: _data.id,
      };
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        loadData();
        // } else {
        //   setValidUsername(false);
      }
    }
  };

  useEffect(() => {
    loadData();
    if (dataExists(user)) {
      setLoggedIn(true);
    }
    console.log(setForceShowLogin);
  }, []);

  useEffect(() => {
    loadData();
  }, [pageNumber]);

  return (
    <>
      <div className="bd-highlight forum-container mt-0">
        <div className="screener-title mt-3 mb-2">
          {localString[lang]["topContributers"]}
        </div>
        <div
          className={`forum-list-container ${
            mode === "dark" ? "forum-dark" : "forum-light"
          }`}
        >
          {data.map((userData) => {
            return (
              <div
                key={userData.id}
                className="d-flex justify-content-between align-items-center mt-2 mb-3"
              >
                <div className="d-flex me-3">
                  {userData.profile_picture ? (
                    <img
                      src={userData.profile_picture}
                      className="rounded-circle"
                    ></img>
                  ) : (
                    <img
                      src="/assets/user.png"
                      className="rounded-circle"
                    ></img>
                  )}
                  <div>
                    <a
                      href={`/profiles/${userData.username}`}
                      className="text-decoration-none"
                    >
                      <div className="screener-title">{`${userData.first_name} ${userData.last_name}`}</div>
                      <div className="forum-list-title">
                        <div>{userData.title}</div>
                        <div>{`${localString[lang]["followers"]}: ${userData.followers}`}</div>
                        <div>{`${localString[lang]["upvotes"]}: ${userData.total_upvotes}`}</div>
                        {/* <div>{formatDate(userData.created_at)}</div> */}
                      </div>
                    </a>
                  </div>
                </div>
                {user?.username !== userData?.username && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      loggedin ? (
                        <span></span>
                      ) : (
                        <Tooltip id="tooltip">
                          {localString[lang].loginToSP}
                        </Tooltip>
                      )
                    }
                  >
                    <div className="forum-list-index">
                      {userData.following ? (
                        <Button
                          variant="info"
                          size="sm"
                          // disabled={!loggedin}
                          onClick={() => {
                            if (!loggedin) {
                              setForceShowLogin(true);
                            } else {
                              unfollow(userData);
                            }
                          }}
                        >
                          {localString[lang]["unfollow"]}
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          size="sm"
                          // disabled={!loggedin}
                          onClick={() => {
                            if (!loggedin) {
                              setForceShowLogin(true);
                            } else {
                              follow(userData);
                            }
                          }}
                        >
                          {localString[lang]["follow"]}
                        </Button>
                      )}
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TopContributers;
