import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import { LANGUAGES } from "../../constants";
import { localString } from "../../localization/localString";
import { setAccessTokenToHeader } from "../../services/helper";
import { dataExists } from "../../helpers";
import { Typeahead } from "react-bootstrap-typeahead";
import compaines from "../../data/companies.json";
import {
  InputGroup,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

const SPSidebar = ({ mode, lang, user }) => {
  const [colorMode, setColorMode] = useState(null);
  const searchref = useRef(null);

  const cmdFFunction = useCallback((event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "f") {
      event.preventDefault();

      let typeaheadInput = document.querySelector(".typehead-search-ig");
      searchref.current.focus();
      if (typeaheadInput) {
        typeaheadInput.focus(); // Focus the actual Typeahead input
      }

      // if (searchref.current) {
      //   searchref.current.getInput().focus(); // Focus the actual Typeahead input
      // }

      searchref.current.focus();
      //Do whatever when esc is pressed
      // setSelectedId(-1);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", cmdFFunction, false);

    return () => {
      document.removeEventListener("keydown", cmdFFunction, false);
    };
  }, [cmdFFunction]);

  const CustomSearchBar = (props) => {
    return (
      <div className="searchbar-container">
        <FormControl {...props} className="search-input" />
        <span className="search-icon">
          <i className="bi bi-command me-2"></i>
          <span>F</span>
        </span>
      </div>
    );
  };

  useEffect(() => {
    setColorMode(mode);
  }, [mode]);

  const logout = () => {
    setAccessTokenToHeader();
    localStorage.setItem("accessToken", null);
    localStorage.setItem("refreshToken", null);
    localStorage.setItem("user", null);
    window.location = "/";
  };
  return (
    <section
      className={`sidebar ${
        colorMode === "dark" ? "background-dark" : "background-light"
      }`}
    >
      <div className="mb-3 cursor-pointer">
        <InputGroup>
          <Typeahead
            className="typeahead-translate"
            ref={searchref}
            id={`typehead-search`}
            input={`.typehead-search-ig`}
            onChange={(data) => {
              window.location = `/companies/stock-metrics/${data[0].ticker}`;
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                if (event.target?.value) {
                  window.location = `/companies/stock-metrics/${event.target?.value}`;
                }
              }
            }}
            inputProps={{
              style: {
                borderBottomLeftRadius: "3px",
                borderBottomRightRadius: "3px",
                borderTopLeftRadius: "3px",
                borderTopRightRadius: "3px",
                fontSize: "14px",
              },
            }}
            options={compaines}
            placeholder={localString[lang].search}
            // renderInput={(inputProps) => <CustomSearchBar {...inputProps} />}
          />
          <div className="searchbar-container">
            {/* <FormControl className="search-input" /> */}
            <span className="search-icon">
              <i className="bi bi-command me-1"></i>
              <span>F</span>
            </span>
          </div>
        </InputGroup>

        {/* <i className="bi bi-graph-up-arrow me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/companies/stock-metrics"
        >
          <span>{localString[lang].Stock}</span>
        </Link> */}
      </div>
      <div className="mb-3 cursor-pointer">
        <i className="bi bi-graph-up-arrow me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/companies/stock-metrics"
        >
          <span>{localString[lang].Stock}</span>
        </Link>
      </div>

      <div className="mb-3 ms-3 cursor-pointer">
        <i className="bi bi-search me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/screener"
        >
          <span>{localString[lang].stockScreener}</span>
        </Link>
      </div>

      <div className="mb-3 ms-3 cursor-pointer">
        <i className="bi bi-chevron-double-up me-2"></i>

        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/top-presets"
        >
          {localString[lang].topPresets}
        </Link>
      </div>

      <div className="mb-3 ms-3 cursor-pointer">
        <i className="bi bi-building-up me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/top-companies"
        >
          {localString[lang].topCompanies}
        </Link>
      </div>

      <div className="mb-3 cursor-pointer">
        <i className="bi bi-arrows-expand-vertical me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/compare"
        >
          {localString[lang].compare}
        </Link>
      </div>
      {dataExists(user) ? (
        <div className="mb-3 cursor-pointer">
          <i className="bi bi-bar-chart-steps me-2"></i>
          <Link
            className={`me-2 no-underline ${
              colorMode === "dark" ? "color-text-dark" : "color-text-light"
            }`}
            to="/portfolio"
          >
            <span>{localString[lang].myPortfolio}</span>
          </Link>
        </div>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">{localString[lang].loginToSP}</Tooltip>
          }
        >
          <div className="mb-3 cursor-pointer opacity-50">
            <i className="bi bi-bar-chart-steps me-2"></i>
            <span>{localString[lang].myPortfolio}</span>
          </div>
        </OverlayTrigger>
      )}
      <div className="mb-3 cursor-pointer">
        <i className="bi bi-award me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/portfolio-leaderboard"
        >
          <span>{localString[lang].portfolioLeader}</span>
        </Link>
      </div>
      <div className="mb-3 cursor-pointer">
        <i className="bi bi-calendar3 me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/earnings-calendar"
        >
          {localString[lang].earningsCalendar}
        </Link>
      </div>

      <div className="mb-3 cursor-pointer">
        <i className="bi bi-ui-checks me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/forum"
        >
          {localString[lang].forum}
        </Link>
      </div>

      <div className="mb-3 cursor-pointer">
        <i className="bi bi-ui-checks me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/terms"
        >
          {localString[lang].Terms}
        </Link>
      </div>
      <div className="mb-3 cursor-pointer">
        <i className="bi bi-ui-checks me-2"></i>
        <Link
          className={`me-2 no-underline ${
            colorMode === "dark" ? "color-text-dark" : "color-text-light"
          }`}
          to="/privacy-policy"
        >
          {localString[lang].Privacy}
        </Link>
      </div>
      {dataExists(user) && (
        <div className="mb-3 cursor-pointer">
          <p
            className={`me-2 no-underline ${
              colorMode === "dark" ? "color-text-dark" : "color-text-light"
            }`}
            onClick={() => logout()}
          >
            <i className="bi bi-person-slash me-2"></i>
            {localString[lang].logout}
          </p>
        </div>
      )}
      {dataExists(user) && (
        <a href={`/profiles/${user["username"]}`}>
          <div className="mb-3 username-field">
            <i className="bi bi-person-workspace me-2"></i>
            {`${user["first_name"]} ${user["last_name"]}`}
          </div>
        </a>
      )}
    </section>
  );
};

export default SPSidebar;
