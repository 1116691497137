import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";
import ForumComments from "./ForumComments";
import TopContributers from "./TopContributers";
import Parser from "html-react-parser";

const ForumDetails = ({ lang, mode, user, setForceShowLogin }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);
  const [loggedin, setLoggedin] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalComments, setTotalComments] = useState(0);
  const [userComment, setUserComment] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [following, setFollowing] = useState(false);
  const [userid, setUserid] = useState(false);
  const [me, setMe] = useState(false);

  const [upvoted, setUpvoted] = useState(false);
  const [downvoted, setDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [downvoteCount, setDownvoteCount] = useState(0);
  const [userVotetype, setUserVotetype] = useState(null);

  const navigate = useNavigate();
  const { slug } = useParams();

  const loadQuestionData = async () => {
    let fullUrl = `${apiEndPoints.getQuestions}/${slug}/`;

    let headers = {
      "Content-Type": "application/json",
    };
    if (dataExists(user)) {
      headers["Authorization"] = `Bearer ${user?.token}`;
    }

    WebService.get(fullUrl, { headers }).then((response) => {
      let _data = response?.data;
      if (_data) {
        console.log(_data);
        setQuestionDetails(_data);
        setFollowing(_data.following);
        setUserid(_data.userid);
        if (dataExists(user) && user.username === _data.username) {
          setMe(true);
        }
      }
    });
  };

  const vote = async (type) => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.vote}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        content_type: "question",
        object_id: questionDetails.id,
        vote_type: type,
      };
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        if (userVotetype === true) {
          if (type === true) {
            // Delete UpVote
            setUserVotetype(null);
            setUpvoted(false);
            setUpvoteCount(upvoteCount - 1);
          } else {
            // Change to DownVote
            setUserVotetype(false);
            setUpvoted(false);
            setUpvoteCount(upvoteCount - 1);
            setDownvoteCount(downvoteCount + 1);
            setDownvoted(true);
          }
        } else if (userVotetype === false) {
          if (type === true) {
            // Change to UpVote
            setUserVotetype(true);
            setUpvoted(true);
            setUpvoteCount(upvoteCount + 1);
            setDownvoteCount(downvoteCount - 1);
            setDownvoted(false);
          } else {
            // Delete Downvote
            setUserVotetype(null);
            setDownvoteCount(downvoteCount - 1);
            setDownvoted(false);
          }
        } else {
          if (type === true) {
            setUpvoted(true);
            setUserVotetype(true);
            setUpvoteCount(upvoteCount + 1);
          } else {
            setDownvoteCount(downvoteCount + 1);
            setDownvoted(true);
            setUserVotetype(false);
          }
        }
      }
    }
  };

  const loadCommentsData = async () => {
    let fullUrl = `${apiEndPoints.getAnswers}/?page=${pageNumber}&page_size=${pageSize}&question_id=${questionDetails?.id}`;
    let headers = {
      "Content-Type": "application/json",
    };
    if (dataExists(user)) {
      headers["Authorization"] = `Bearer ${user?.token}`;
    }

    WebService.get(fullUrl, { headers }).then((response) => {
      let _data = response?.data;
      if (_data) {
        setAllComments(_data?.results);
        setTotalComments(_data?.count);
      }
    });
  };

  const handlePostComment = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.postAnswer}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        question: questionDetails.id,
        body: userComment,
      };
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        setPageNumber(1);
        setUserComment("");
        loadCommentsData();
      } else {
        // setError(localString[lang]["errorCreatingQuestion"]);
      }
    }
  };

  useEffect(() => {
    loadQuestionData();
    if (dataExists(user)) {
      setLoggedin(true);
    }
  }, [slug]);

  useEffect(() => {
    if (questionDetails) {
      if (questionDetails) {
        setDownvoteCount(questionDetails.downvote_count);
        setUpvoteCount(questionDetails.upvote_count);
        setUserVotetype(questionDetails?.user_votetype);
      }

      if (questionDetails?.user_votetype === true) {
        setUpvoted(true);
      } else if (questionDetails?.user_votetype === false) {
        setDownvoted(true);
      } else {
        setUpvoted(false);
        setDownvoted(false);
      }

      loadCommentsData();
    }
  }, [questionDetails, pageNumber]);

  const enablePostComment = () => {
    return !(
      // loggedin &&
      (userComment !== null && userComment !== "" && questionDetails?.id)
    );
  };

  const follow = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.follow}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        user: userid,
      };
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        setFollowing(true);
        // } else {
        //   setValidUsername(false);
      }
    }
  };
  const unfollow = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.unFollow}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        user: userid,
      };
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        setFollowing(false);
        // } else {
        //   setValidUsername(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <div className="d-flex">
        <Stack
          className={`content forum-details pb-3 forum-left ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
        >
          {questionDetails && (
            <>
              <div className="ps-2 pt-2">
                <span
                  className="cursor-pointer text-body-tertiary small"
                  onClick={() => {
                    navigate("/forum");
                    // if (window.history.length > 2) {
                    //   navigate(-1);
                    // } else {
                    //   navigate("/forum");
                    // }
                  }}
                >
                  <i className="bi bi-arrow-left"></i>{" "}
                  {localString[lang]["back"]}
                </span>
              </div>
              <div className="d-flex justify-content-between flex-row flex-wrap bd-highlight forum-container">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {questionDetails.formatted_title}
                    {questionDetails?.formatted_short_description && (
                      <>
                        <br />
                        <i>
                          <span className="text-body-tertiary font-14">
                            {questionDetails.formatted_short_description}
                          </span>
                        </i>
                      </>
                    )}
                    <div className="d-flex forum-list-title">
                      <div className="d-flex align-items-center me-3">
                        <i className="bi bi-circle-fill forum-list-icon me-1"></i>
                        {formatDate(questionDetails.created_at)}
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <i className="bi bi-circle-fill forum-list-icon me-1"></i>
                        <a
                          href={`/profiles/${questionDetails.username}`}
                          className="text-decoration-none"
                        >
                          {questionDetails.user_fullname}
                        </a>
                      </div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          loggedin ? (
                            <span></span>
                          ) : (
                            <Tooltip id="tooltip">
                              {localString[lang].loginToSP}
                            </Tooltip>
                          )
                        }
                      >
                        <div
                          className="d-flex align-items-center me-3 chart-numbers-change-green cursor-pointer"
                          onClick={() => {
                            if (!loggedin) {
                              setForceShowLogin(true);
                            } else {
                              vote(true);
                            }
                          }}
                        >
                          <i
                            className={`bi ${
                              upvoted
                                ? "bi-hand-thumbs-up-fill"
                                : "bi-hand-thumbs-up"
                            }  forum-list-icon me-1 vote-icon`}
                          ></i>
                          {upvoteCount}
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          loggedin ? (
                            <span></span>
                          ) : (
                            <Tooltip id="tooltip">
                              {localString[lang].loginToSP}
                            </Tooltip>
                          )
                        }
                      >
                        <div
                          className="d-flex align-items-center me-3 chart-numbers-change-red cursor-pointer"
                          onClick={() => {
                            if (!loggedin) {
                              setForceShowLogin(true);
                            } else {
                              vote(false);
                            }
                          }}
                        >
                          <i
                            className={`bi ${
                              downvoted
                                ? "bi-hand-thumbs-down-fill"
                                : "bi-hand-thumbs-down"
                            } forum-list-icon me-1 vote-icon`}
                          ></i>
                          {downvoteCount}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bd-highlight forum-container mt-0">
                <div className="d-flex justify-content-between mb-4">
                  <div className="forum-details-index">
                    {questionDetails?.category && (
                      <a
                        href={`/forum/filter?category=${questionDetails.category}`}
                      >
                        <Button variant="primary" size="sm">
                          {questionDetails.category}
                        </Button>
                      </a>
                    )}
                    {questionDetails?.ticker && (
                      <a
                        href={`/companies/stock-metrics/${questionDetails.ticker}`}
                      >
                        <Button variant="primary" size="sm">
                          {questionDetails.ticker}
                        </Button>
                      </a>
                    )}
                  </div>
                </div>
                {questionDetails?.formatted_body && (
                  <>
                    <div
                      className={`forum-list-container ${
                        mode === "dark" ? "forum-dark" : "forum-light"
                      }`}
                    >
                      {Parser(questionDetails?.formatted_body)}
                    </div>
                    <div className="mt-2 d-flex forum-list-title">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          loggedin ? (
                            <span></span>
                          ) : (
                            <Tooltip id="tooltip">
                              {localString[lang].loginToSP}
                            </Tooltip>
                          )
                        }
                      >
                        <div
                          className="d-flex align-items-center me-3 chart-numbers-change-green cursor-pointer"
                          onClick={() => {
                            if (!loggedin) {
                              setForceShowLogin(true);
                            } else {
                              vote(true);
                            }
                          }}
                        >
                          <i
                            className={`bi ${
                              upvoted
                                ? "bi-hand-thumbs-up-fill"
                                : "bi-hand-thumbs-up"
                            }  forum-list-icon me-1 vote-icon`}
                          ></i>
                          {upvoteCount}
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          loggedin ? (
                            <span></span>
                          ) : (
                            <Tooltip id="tooltip">
                              {localString[lang].loginToSP}
                            </Tooltip>
                          )
                        }
                      >
                        <div
                          className="d-flex align-items-center me-3 chart-numbers-change-red cursor-pointer"
                          onClick={() => {
                            if (!loggedin) {
                              setForceShowLogin(true);
                            } else {
                              vote(false);
                            }
                          }}
                        >
                          <i
                            className={`bi ${
                              downvoted
                                ? "bi-hand-thumbs-down-fill"
                                : "bi-hand-thumbs-down"
                            } forum-list-icon me-1 vote-icon`}
                          ></i>
                          {downvoteCount}
                        </div>
                      </OverlayTrigger>
                      {!me ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            loggedin ? (
                              <span></span>
                            ) : (
                              <Tooltip id="tooltip">
                                {localString[lang].loginToSP}
                              </Tooltip>
                            )
                          }
                        >
                          <div className="p-2 cursor-pointer">
                            {!following ? (
                              <Button
                                variant="info"
                                size="sm"
                                // disabled={!loggedin}
                                onClick={() => {
                                  if (!loggedin) {
                                    setForceShowLogin(true);
                                  } else {
                                    follow();
                                  }
                                }}
                              >
                                {localString[lang]["follow"]}
                              </Button>
                            ) : (
                              <Button
                                variant="info"
                                size="sm"
                                // disabled={!loggedin}
                                onClick={() => {
                                  if (!loggedin) {
                                    setForceShowLogin(true);
                                  } else {
                                    unfollow();
                                  }
                                }}
                              >
                                {localString[lang]["unfollow"]}
                              </Button>
                            )}
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>

              <OverlayTrigger
                placement="top"
                overlay={
                  loggedin ? (
                    <span></span>
                  ) : (
                    <Tooltip id="tooltip">
                      {localString[lang].loginToSP}
                    </Tooltip>
                  )
                }
              >
                <div className="bd-highlight forum-container mt-0">
                  <div className="screener-title mb-2">
                    {localString[lang]["comments"]}
                  </div>
                  <div className="d-flex">
                    {user?.profile_picture ? (
                      <img
                        src={user.profile_picture}
                        className="rounded-circle"
                      ></img>
                    ) : (
                      <img
                        src="/assets/user.png"
                        className="rounded-circle"
                      ></img>
                    )}
                    <Form className="w-100">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={2}
                          disabled={!loggedin}
                          value={userComment}
                          onChange={(e) => {
                            setUserComment(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="d-flex float-end">
                    <Button
                      variant="primary"
                      size="sm"
                      className="large-button"
                      disabled={enablePostComment()}
                      onClick={() => {
                        if (!loggedin) {
                          setForceShowLogin(true);
                        } else {
                          handlePostComment();
                        }
                      }}
                    >
                      {localString[lang]["postComments"]}
                    </Button>
                  </div>
                </div>
              </OverlayTrigger>

              {/* User Comments */}
              {allComments !== null &&
                allComments?.length > 0 &&
                allComments.map((comment) => (
                  <ForumComments
                    key={comment?.id}
                    comment={comment}
                    user={user}
                    lang={lang}
                    mode={mode}
                    questionDetails={questionDetails}
                    setForceShowLogin={setForceShowLogin}
                  />
                ))}

              {totalComments > 0 && (
                <div className="text-center">
                  <PaginationComponent
                    itemsCount={totalComments}
                    itemsPerPage={pageSize}
                    currentPage={pageNumber}
                    setCurrentPage={setPageNumber}
                  />
                </div>
              )}
            </>
          )}
        </Stack>

        <Stack
          className={`content screener forum-right ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <TopContributers
            mode={mode}
            lang={lang}
            user={user}
            setForceShowLogin={setForceShowLogin}
          />
        </Stack>
      </div>
    </>
  );
};

export default ForumDetails;
