import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import {
  FORUM_CATEGORIES,
  FORUM_SUBCATEGORIES,
  LANGUAGES,
  scannerOptions,
} from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginatedList from "./PaginatedList";
import TopContributers from "./TopContributers";
import compaines from "../../data/companies.json";
import { Typeahead } from "react-bootstrap-typeahead";

const ForumListing = ({ lang, mode, user, setForceShowLogin }) => {
  const [loggedin, setLoggedIn] = useState(false);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [categoryData, setCategoryData] = useState(FORUM_CATEGORIES);
  const [subcategoryData, setSubcategoryData] = useState(FORUM_SUBCATEGORIES);

  const [ticker, setTicker] = useState(null);
  useEffect(() => {
    if (dataExists(user)) {
      setLoggedIn(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <div className="d-flex">
        <Stack
          className={`content screener forum-left ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
        >
          <div className="d-flex justify-content-between flex-row flex-wrap bd-highlight forum-container">
            <div className="d-flex">
              <div className="screener-title me-4">
                <InputGroup className="pt-2">
                  <div className="d-flex">
                    <Form.Select
                      aria-label="Category select"
                      size="sm"
                      className="me-1"
                      value={category}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value === 0) {
                          setCategory(0);
                        } else {
                          window.location = `/forum/filter?category=${value}`;
                          // setCategory(value);
                          // setSubcategory(0);
                        }
                      }}
                    >
                      {categoryData.map((_category) => {
                        return (
                          <option key={_category.name} value={_category.value}>
                            {
                              localString[lang]["forumCategories"][
                                _category.name
                              ]
                            }
                          </option>
                        );
                      })}
                    </Form.Select>
                    {category && (
                      <Form.Select
                        aria-label="Category select"
                        size="sm"
                        value={subcategory}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value === 0) {
                            setSubcategory(0);
                          } else {
                            window.location = `/forum/filter?subcategory=${value}`;
                            // setSubcategory(value);
                          }
                        }}
                      >
                        {subcategoryData[category]?.map((_subcategory) => {
                          return (
                            <option
                              key={_subcategory.name}
                              value={_subcategory.value}
                            >
                              {
                                localString[lang]["forumCategories"][
                                  _subcategory.name
                                ]
                              }
                            </option>
                          );
                        })}
                      </Form.Select>
                    )}
                  </div>
                </InputGroup>
              </div>
              <div className="screener-title">
                <Typeahead
                  className="typeahead-translate pt-2"
                  id={`typehead-search`}
                  input={`.typehead-search-ig`}
                  size="sm"
                  onChange={(data) => {
                    if (data[0]) {
                      // setTicker(data[0].ticker);
                      window.location = `/forum/filter?ticker=${data[0].ticker}`;
                    } else {
                      setTicker(null);
                    }
                  }}
                  inputProps={{
                    style: {
                      borderBottomLeftRadius: "3px",
                      borderBottomRightRadius: "3px",
                      borderTopLeftRadius: "3px",
                      borderTopRightRadius: "3px",
                      fontSize: "14px",
                    },
                  }}
                  options={compaines}
                  placeholder={localString[lang].search}
                />
              </div>
            </div>
            <div className="d-flex">
              <Button
                size="sm"
                // href="/forum/create"
                onClick={() => {
                  if (!loggedin) {
                    setForceShowLogin(true);
                  } else {
                    window.location = "/forum/create";
                  }
                }}
                className="mt-2"
                variant="info"
              >
                Create a Post
              </Button>
            </div>
          </div>

          <PaginatedList
            lang={lang}
            mode={mode}
            user={user}
            category={category}
            subcategory={subcategory}
            ticker={ticker}
            username={""}
            title={localString[lang]["topArticles"]}
          ></PaginatedList>
          <PaginatedList
            lang={lang}
            mode={mode}
            user={user}
            category={"Analysis"}
            username={""}
            title={localString[lang]["topAnalysis"]}
          ></PaginatedList>
          <PaginatedList
            lang={lang}
            mode={mode}
            user={user}
            subcategory={"Ask me Anything"}
            username={""}
            title={localString[lang]["topAMA"]}
          ></PaginatedList>
          <PaginatedList
            lang={lang}
            mode={mode}
            user={user}
            category={""}
            username={""}
            trending={"yes"}
            title={localString[lang]["trending"]}
          ></PaginatedList>
          <PaginatedList
            lang={lang}
            mode={mode}
            user={user}
            category={""}
            username={""}
            following={"yes"}
            title={localString[lang]["following"]}
          ></PaginatedList>
        </Stack>

        <Stack
          className={`content screener forum-right ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <TopContributers
            mode={mode}
            lang={lang}
            user={user}
            setForceShowLogin={setForceShowLogin}
          />
        </Stack>
      </div>
    </>
  );
};

export default ForumListing;
