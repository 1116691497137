import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/Home";
import "./styles/style.css";
import Compare from "./pages/Compare";
import TopCompaniesPage from "./pages/TopCompanies";
import Screener from "./pages/Screener";
import Index from "./pages/Index";
import { setAccessTokenToHeader } from "./services/helper";
import TopPresetsPage from "./pages/TopPresets";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import PortfolioLeaderboard from "./pages/PortfolioLeaderboard";
import UserPortfolios from "./pages/UserPortfolios";
import ForumPage from "./pages/ForumPage";
import ForumCreatePage from "./pages/ForumCreatePage";
import ForumDetailsPage from "./pages/ForumDetailsPage";
import ForumProfilePage from "./pages/ForumProfilePage";
import { WebService } from "./services/webServices";
import { apiEndPoints } from "./services/endpoints";
import ForumFilterPage from "./pages/ForumFilterPage";
import EarningsCalendarPage from "./pages/EarningsCalendarPage";

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    async function init() {
      if (user?.token) {
        setAccessTokenToHeader(user.token);
        let fullUrl = `${apiEndPoints.me}/`;
        let headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        };

        let response = await WebService.post(
          fullUrl,
          {},
          {
            headers: headers,
          }
        );
        if (response?.status >= 200 && response?.status < 300) {
          let _userJson = response?.data;
          _userJson["token"] = user.token;
          _userJson["refresh"] = user.refresh;
          localStorage.setItem("user", JSON.stringify(_userJson));
          setUser(_userJson);
        } else {
          localStorage.setItem("user", JSON.stringify(null));
          setUser(null);
        }
      }
    }
    init();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<TopCompaniesPage user={user} showSearch={true} />}
        />
        {/* <Route path='/login' element={<LoginPage />} /> */}
        {/* <Route path='/' element={<HomePage />} /> */}
        <Route path="/compare" element={<Compare user={user} />} />
        <Route
          path="/companies/stock-metrics"
          element={<HomePage user={user} />}
        />
        <Route
          path="/top-companies"
          element={<TopCompaniesPage user={user} />}
        />
        <Route path="/terms" element={<Terms user={user} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy user={user} />} />
        <Route path="/top-presets" element={<TopPresetsPage user={user} />} />
        <Route path="/portfolio" element={<PersonalPortfolio user={user} />} />
        <Route
          path="/portfolio-leaderboard"
          element={<PortfolioLeaderboard user={user} />}
        />
        <Route path="/screener" element={<Screener user={user} />} />
        <Route
          path="/companies/stock-metrics/:id"
          element={<HomePage user={user} />}
        />
        <Route path="/portfolio/:id" element={<UserPortfolios user={user} />} />
        <Route path="/forum/" element={<ForumPage user={user} />} />
        <Route path="/earnings-calendar/" element={<EarningsCalendarPage user={user} />} />
        <Route path="/forum/create" element={<ForumCreatePage user={user} />} />
        <Route path="/forum/:slug" element={<ForumDetailsPage user={user} />} />
        <Route path="/forum/filter" element={<ForumFilterPage user={user} />} />
        <Route
          path="/profiles/:username"
          element={<ForumProfilePage user={user} setUser={setUser} />}
        />

        <Route path="/index/:id" element={<Index user={user} />} />
        <Route path="*" element={<Navigate to={{ pathname: "/" }} />} />
      </Routes>
    </Router>
  );
}

export default App;
