import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";
import Parser from "html-react-parser";

const ForumNestedComments = ({
  lang,
  mode,
  user,
  comment,
  questionDetails,
  setForceShowLogin,
  setLoadNestedComments,
  showCommentBox
}) => {
  const [upvoted, setUpvoted] = useState(false);
  const [downvoted, setDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [downvoteCount, setDownvoteCount] = useState(0);
  const [userVotetype, setUserVotetype] = useState(null);
  const [loggedin, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [loadNestedComments, setLoadNestedComments] = useState(false);

  // const loadData = async () => {
  //   // if (dataExists(user)) {
  //   let fullUrl = `${apiEndPoints.getQuestions}/?page=${pageNumber}&page_size=${pageSize}&`;

  //   if (dataExists(category)) {
  //     fullUrl += "category=" + category + "&";
  //   }
  //   if (dataExists(username)) {
  //     fullUrl += "username=" + username;
  //   }

  //   WebService.get(fullUrl).then((response) => {
  //     let _data = response?.data;
  //     if (_data) {
  //       setData(_data?.results);
  //       setTotalQuestions(_data?.count);
  //       let _totalPages = Math.ceil(_data?.count / pageSize);
  //       setTotalPages(_totalPages);
  //     }
  //   });
  //   // }
  // };
  console.log("HERREE ", comment);

  useEffect(() => {
    console.log(comment);
    if (dataExists(comment)) {
      setLoading(false);
    }
  }, [comment]);

  const vote = async (type) => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.vote}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        content_type: "answer",
        object_id: comment.id,
        vote_type: type,
      };
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        if (userVotetype === true) {
          if (type === true) {
            // Delete UpVote
            setUserVotetype(null);
            setUpvoted(false);
            setUpvoteCount(upvoteCount - 1);
          } else {
            // Change to DownVote
            setUserVotetype(false);
            setUpvoted(false);
            setUpvoteCount(upvoteCount - 1);
            setDownvoteCount(downvoteCount + 1);
            setDownvoted(true);
          }
        } else if (userVotetype === false) {
          if (type === true) {
            // Change to UpVote
            setUserVotetype(true);
            setUpvoted(true);
            setUpvoteCount(upvoteCount + 1);
            setDownvoteCount(downvoteCount - 1);
            setDownvoted(false);
          } else {
            // Delete Downvote
            setUserVotetype(null);
            setDownvoteCount(downvoteCount - 1);
            setDownvoted(false);
          }
        } else {
          if (type === true) {
            setUpvoted(true);
            setUserVotetype(true);
            setUpvoteCount(upvoteCount + 1);
          } else {
            setDownvoteCount(downvoteCount + 1);
            setDownvoted(true);
            setUserVotetype(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (comment) {
      setDownvoteCount(comment.downvote_count);
      setUpvoteCount(comment.upvote_count);
      setUserVotetype(comment?.user_votetype);
    }

    if (comment?.user_votetype === true) {
      setUpvoted(true);
    } else if (comment?.user_votetype === false) {
      setDownvoted(true);
    } else {
      setUpvoted(false);
      setDownvoted(false);
    }
    if (dataExists(user)) {
      setLoggedIn(true);
    }
  }, [comment]);

  return (
    <>
      {/* <div className="bd-highlight forum-container mt-0"> */}
      {loading ? (
        <></>
      ) : (
        <div className="d-flex mt-3">
          {comment?.profile_picture ? (
            <img src={comment.profile_picture} className="rounded-circle"></img>
          ) : (
            <img src="/assets/user.png" className="rounded-circle"></img>
          )}
          <div className="w-100">
            <a
              href={`/profiles/${comment.username}`}
              className="text-decoration-none"
            >
              <span className="d-block mb-1 text-body-secondary">
                {/* <strong> */}
                {comment.user_fullname}
                {/* </strong> */}
              </span>
            </a>
            <span>{Parser(comment.formatted_body)}</span>
            <div className="d-flex forum-list-title">
              <div className="d-flex align-items-center me-3">
                <i className="bi bi-circle-fill forum-list-icon me-1"></i>
                {formatDate(comment.created_at)}
              </div>
              <OverlayTrigger
                placement="top"
                overlay={
                  loggedin ? (
                    <span></span>
                  ) : (
                    <Tooltip id="tooltip">
                      {localString[lang].loginToSP}
                    </Tooltip>
                  )
                }
              >
                <div
                  className="d-flex align-items-center me-3 chart-numbers-change-green cursor-pointer"
                  onClick={() => {
                    if (!loggedin) {
                      setForceShowLogin(true);
                    } else {
                      vote(true);
                    }
                  }}
                >
                  <i
                    className={`bi ${
                      upvoted ? "bi-hand-thumbs-up-fill" : "bi-hand-thumbs-up"
                    }  forum-list-icon me-1 vote-icon`}
                  ></i>
                  {upvoteCount}
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  loggedin ? (
                    <span></span>
                  ) : (
                    <Tooltip id="tooltip">
                      {localString[lang].loginToSP}
                    </Tooltip>
                  )
                }
              >
                <div
                  className="d-flex align-items-center me-3 chart-numbers-change-red cursor-pointer"
                  onClick={() => {
                    if (!loggedin) {
                      setForceShowLogin(true);
                    } else {
                      vote(false);
                    }
                  }}
                >
                  <i
                    className={`bi ${
                      downvoted
                        ? "bi-hand-thumbs-down-fill"
                        : "bi-hand-thumbs-down"
                    } forum-list-icon me-1 vote-icon`}
                  ></i>
                  {downvoteCount}
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  loggedin ? (
                    <span></span>
                  ) : (
                    <Tooltip id="tooltip">
                      {localString[lang].loginToSP}
                    </Tooltip>
                  )
                }
              >
                <div
                  className="d-flex align-items-center me-3 text-primary-emphasis cursor-pointer"
                  onClick={() => {
                    if (!loggedin) {
                      setForceShowLogin(true);
                    } else {
                      showCommentBox(`@${comment.username}`);
                    }
                  }}
                >
                  <i
                    className={`bi bi-reply-all forum-list-icon me-1 vote-icon`}
                  ></i>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </>
  );
};

export default ForumNestedComments;
